body {
  margin: 0;
  font-family: "Gilroy",-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  transition: 0.5s ease-in;
}

.appear {
  display: inherit;
  visibility: visible;
  height: 100%;
  opacity: 1;
  animation: fade-in 0.5s ease-in;

}

.hidden {
  /* display: none !important;
  visibility: hidden !important;
  opacity: 0 !important; */
  animation: fade-out 0.5s ease-out;
  visibility: hidden ;
  display: none;

}

@keyframes fade-in {
  0% {
    opacity: 0;
    height: 0;
  }

  100% {
    height: 100%;
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
    height: 100%;
  }
  
  100% {
    height: 0;
    opacity: 0;
  }
}